import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`20:00 to establish a max of the complex:`}</p>
    <p>{`1-Power Snatch`}</p>
    <p>{`1-Hang Squat Snatch`}</p>
    <p>{`2-OHS`}</p>
    <p>{`then,`}</p>
    <p>{`50-Calorie Ski Erg`}</p>
    <p>{`40-OHS (95/65)`}</p>
    <p>{`30-Calorie Row`}</p>
    <p>{`20-Power Snatch (95/65)`}</p>
    <p>{`10-Bar Muscle Ups`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Wednesday we are shooting a short video during the 10:30
class.  If you’d like to be a part of it please plan on attending!  The
class workout may go until 12:00.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      